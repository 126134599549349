import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconCircleCheck: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="9.6" stroke="white" strokeWidth="1.2" />
    <circle cx="12" cy="12" r="9" />
    <path d="M7.5 12L10.5 15L17 8.5" stroke="white" strokeWidth="1.2" />
  </Icon>
);
