'use client';

import { useField } from 'formik';
import { FC } from 'react';

import { Input } from './Input';
import { InputFormikProps } from './Input.type';

/**
 * Input field expected to be used within a Formik context
 */
export const InputFormik: FC<InputFormikProps<typeof Input>> = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <Input
      {...props}
      value={field.value}
      error={meta.error && meta.touched ? meta.error : undefined}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
};
