import { getOr } from 'src/utils/getOr';

export const handleCarmaClientErrorMessage = (error: any): string => {
  let errorMessage = 'An unknown error happened, please try again later';
  if (error.response) {
    errorMessage = getOr(
      error.response.data,
      'detail[0].msg',
      getOr(error.response.data, 'detail', getOr(error.response.data, 'message', errorMessage)),
    );
  }
  return errorMessage;
};
