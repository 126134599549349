import { AxiosResponse } from 'axios';

import { FiltersPayloadRow } from '../types/CarFilters.types';
import { ProductListDataPayload } from '../types/CataloguePage.types';
import { carmaBizClient } from './instance/carmaBizClient';

export const getPLPCarData = async (data: {
  pageSize?: number;
  page?: number;
  sort?: string;
  text?: string;
  filters?: FiltersPayloadRow[];
  fuzzy?: boolean;
}) => {
  const plpCarData: AxiosResponse<ProductListDataPayload> = await carmaBizClient.post('/vehicles', data, {
    headers: {
      'Content-Type': 'application/plp+json',
    },
  });
  return plpCarData.data;
};
