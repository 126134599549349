import classNames from 'classnames';
import { isNil } from 'lodash';
import { FC, ReactNode } from 'react';

import { getInputErrorId, getInputNoteId } from '../Input.helpers';
import { InputBase, InputBaseWithOptions, InputOptionType } from '../Input.type';
import styles from './FieldWrapper.module.scss';

interface Props extends InputBase<any> {
  children: ReactNode;
}

interface GroupProps extends InputBaseWithOptions<any> {
  type: InputOptionType;
  children: ReactNode;
}

export const FieldWrapper: FC<Props> = ({
  id,
  label,
  subLabel,
  value,
  error,
  disabled,
  note,
  className,
  size = 'default',
  children,
  labelOutside = false,
  iconLeft,
}) => (
  <div className={classNames(styles.root, 'wrapper', className)}>
    <label
      className={classNames(
        styles.wrapper,
        styles[size],
        {
          [styles.filled]: !!value,
          [styles.error]: !!error,
          [styles.disabled]: disabled,
          [styles.noLabel]: isNil(label),
          noLabel: isNil(label),
          [styles.labelOutside]: labelOutside,
          [styles.withIconLeft]: iconLeft,
        },
        'labelWrapper',
      )}
    >
      {!!label && <span className={classNames(styles.label, 'label')}>{label}</span>}
      {labelOutside && !!subLabel && <div className={classNames(styles.subLabel, 'subLabel')}>{subLabel}</div>}
      <div className={classNames(styles.inputWrapper, 'inputWrapper')}>
        {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
        {children}
      </div>
    </label>
    <FieldInfo id={id} error={error} note={note} />
  </div>
);

export const FieldWrapperOptionsGroup: FC<GroupProps> = ({
  id,
  label,
  subLabel,
  type,
  error,
  note,
  className,
  hasTwoColumnsLayout,
  children,
}) => (
  <fieldset
    className={classNames(styles.rootOptionsGroup, className, 'wrapper', `wrapper--${type}`, {
      [styles.rootOptionsGroup2Columns]: hasTwoColumnsLayout === 'all',
      [styles.rootOptionsGroup2ColumnsMdOnly]: hasTwoColumnsLayout === 'mdOnly',
    })}
  >
    {!!label && (
      <>
        <legend className="sr-only">{label}</legend>
        <div className={styles.labelOptionsGroup}>
          {label}
          {!!subLabel && <div className={classNames(styles.subLabel, 'subLabel')}>{subLabel}</div>}
        </div>
      </>
    )}

    {children}
    <FieldInfo id={id} error={error} note={note} />
  </fieldset>
);

const FieldInfo: FC<Pick<Props, 'id' | 'error' | 'note'>> = ({ id, error, note }) => (
  <>
    {/* Field error */}
    {!!error && (
      <small className={classNames(styles.note, styles.error, 'note', 'error')} id={getInputErrorId(id)}>
        {error}
      </small>
    )}
    {/* Field note */}
    {!!note && (
      <small className={classNames(styles.note, 'note')} id={getInputNoteId(id)}>
        {note}
      </small>
    )}
  </>
);
