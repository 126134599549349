import { InputBase } from './Input.type';

export const getInputErrorId = (id: string) => `${id}-error`;
export const getInputNoteId = (id: string) => `${id}-note`;

export const getInputAria = (inputProps: InputBase<any>) => {
  const result: { [key: string]: string | number | boolean } = {};

  if (!!inputProps.error) {
    result['aria-errormessage'] = getInputErrorId(inputProps.id);
  }
  if (!!inputProps.note) {
    result['aria-labelledby'] = getInputNoteId(inputProps.id);
  }

  return result;
};
