import classNames from 'classnames';
import { isArray, omit } from 'lodash';
import { FC, InputHTMLAttributes } from 'react';

import { getInputAria } from './Input.helpers';
import { InputBaseSelect } from './Input.type';
import { FieldWrapper } from './atoms/FieldWrapper';

type Props = Prettify<InputBaseSelect<string | number> & Omit<InputHTMLAttributes<HTMLSelectElement>, 'size'>>;

export const InputSelect: FC<Props> = ({ className, placeholder, allowEmpty, labelOutside, subLabel, ...props }) => {
  const selectProps = omit(props, 'options', 'size');
  const renderOptions = () => {
    let result = [];
    const { options } = props;
    if (isArray(options)) {
      result = options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ));
    } else {
      for (const key in options) {
        result.push(
          <optgroup label={key} key={key}>
            {options[key].map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </optgroup>,
        );
      }
    }
    return result;
  };
  return (
    <FieldWrapper
      {...props}
      className={classNames(className, 'selectWrapper')}
      labelOutside={labelOutside}
      subLabel={subLabel}
    >
      <select {...getInputAria(props)} {...selectProps}>
        {placeholder && <option hidden>{placeholder}</option>}
        {allowEmpty && (
          <option aria-label="None" value="">
            {typeof allowEmpty === 'string' ? allowEmpty : null}
          </option>
        )}
        {renderOptions()}
      </select>
    </FieldWrapper>
  );
};
