import { FC, InputHTMLAttributes, forwardRef } from 'react';

import { getInputAria } from './Input.helpers';
import { InputBase } from './Input.type';
import { FieldWrapper } from './atoms/FieldWrapper';

type Props = Prettify<InputBase<string | number> & InputHTMLAttributes<HTMLInputElement>>;

export const Input: FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ className, inputClassName, inert, disabled, placeholder, labelOutside, subLabel, ...props }, ref) => {
    let additionalInputProps: InputHTMLAttributes<HTMLInputElement> | undefined = undefined;
    if (props.type === 'number') {
      additionalInputProps = {
        pattern: '[0-9]*',
        inputMode: 'numeric',
      };
    }

    return (
      <FieldWrapper
        {...props}
        disabled={disabled}
        className={className}
        labelOutside={labelOutside}
        subLabel={subLabel}
      >
        <input
          ref={ref}
          {...additionalInputProps}
          {...props}
          {...getInputAria(props)}
          disabled={disabled}
          className={inputClassName}
          inert={inert}
          placeholder={placeholder ?? ' '} // placeholder must to be " " when nullish to avoid ios styling issues
        />
      </FieldWrapper>
    );
  },
);

Input.displayName = 'Input';
