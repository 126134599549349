import { FormControl, FormGroup, FormHelperText, FormLabel, FormLabelBaseProps, InputLabel } from '@material-ui/core';
import classNames from 'classnames';
import { useField } from 'formik';
import { isNil } from 'lodash';
import { FC, Fragment, ReactNode, useMemo } from 'react';

import ReactMarkdown from 'react-markdown';
import classes from './FormikInput.module.scss';
import { FormikInputBaseProps, FormikInputSizeProps, FormikInputWrapperChildProps } from './FormikInput.types';

type FormikInputWrapperProps<T extends {}, V = any> = FormikInputBaseProps &
  FormikInputSizeProps &
  T & {
    isGroup?: boolean;
    testIdPrefix?: string;
    Component: FC<
      FormikInputWrapperChildProps<V> &
        Omit<
          FormikInputWrapperProps<T>,
          'isGroup' | 'Component' | keyof FormikInputBaseProps | keyof FormikInputSizeProps
        >
    >;
  };

export function FormikInputWrapper<T extends {}>({
  name,
  label,
  helpText,
  className,
  disabled,
  size = 'medium',
  Component,
  isGroup,
  replaceHelpTextWhenError,
  InputLabelProps,
  ...componentProps
}: FormikInputWrapperProps<T>) {
  const [field, meta, helpers] = useField(name);
  const showError = meta.touched && !!meta.error;

  const [Label, ChildrenWrapper, wrapperComponent] = useMemo(
    () => (isGroup ? ([GroupLabel, FormGroup, 'fieldset'] as const) : ([InputLabel, Fragment, 'div'] as const)),
    [isGroup],
  );

  return (
    <FormControl
      fullWidth
      variant="filled"
      error={showError}
      onBlur={() => helpers.setTouched(false)}
      disabled={disabled}
      component={wrapperComponent}
      className={classNames(classes.root, size && classes[size], className, {
        [classes.error]: showError,
      })}
    >
      <Label {...InputLabelProps}>
        <>{label}</>
      </Label>
      <ChildrenWrapper>
        <Component {...componentProps} field={field} helpers={helpers} disabled={disabled} />
      </ChildrenWrapper>
      {showError && (
        <FormHelperText
          error
          data-testid={
            componentProps.testIdPrefix ? `${componentProps.testIdPrefix}-component-errorMessage` : undefined
          }
          component="div"
        >
          <ReactMarkdown>{meta.error || ''}</ReactMarkdown>
        </FormHelperText>
      )}
      {!(isNil(helpText) || (replaceHelpTextWhenError && showError)) && (
        <FormHelperText error={false} component="div">
          <>{helpText}</>
        </FormHelperText>
      )}
    </FormControl>
  );
}

interface GroupLabelProps extends Pick<FormLabelBaseProps, 'id' | 'htmlFor'> {
  children?: ReactNode;
}

const GroupLabel: FC<GroupLabelProps> = ({ children, ...props }) =>
  children ? (
    <FormLabel {...props} component="legend">
      <>{children}</>
    </FormLabel>
  ) : null;
